<template>
  <div class="userinfo">
    <div v-if="authorized">
      <UserInfoComponent/>
    </div>
    <div v-else>
      <RegisterComponent/>
    </div>
  </div>
</template>

<script>
import UserInfoComponent from '@/components/UserInfoComponent.vue'
import RegisterComponent from '@/components/RegisterComponent.vue'
import {useAuthorization} from "../js/authorization.js"

export default {
  name: 'UserInfo',
  components: {
    UserInfoComponent,
    RegisterComponent
  },
  data() {
    const {authorized, checkAuthorization, changeAuthorization} = useAuthorization(false);

    return {
      authorized: authorized,
      checkAuthorization: checkAuthorization,
      changeAuthorization: changeAuthorization,
    }
  },
  async mounted() {
    this.checkAuthorization(this.$cookies.get("GlobalChat-Login"))
  },
}
</script>
