<template>
  <section class="text-gray-700 body-font">
    <div
        class="container flex flex-col items-center px-5 py-16 mx-auto lg:px-20 lg:py-24 md:flex-row"
    >
      <div
          class="flex flex-col items-center w-full pt-0 mb-16 text-left lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 lg:mr-20 md:items-start md:text-left md:mb-0"
      >
        <h2
            class="mb-1 px-1 text-xs font-medium tracking-widest text-white lg:text-left title-font"
        >
          LOGIN
        </h2>
        <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
          Log in with your account
        </h1>
        <p
            class="mb-8 text-base leading-relaxed text-white opacity-80 lg:text-left lg:text-1xl"
        >
          To start authentication, use LabyMod OAuth or open the addon settings and click the "Dashboard" button.
        </p>
        <div class="flex justify-center">
          <button @click="beginLabyAuth()"
                  class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none">
            Login with LabyMod
          </button>
        </div>
      </div>
      <div
          class="shadow-2xl rounded-lg w-5/6 mb-10 lg:max-w-lg lg:w-full md:w-1/2 md:mb-0"
      >
        <video
            class="rounded-lg transform transition hover:scale-105 duration-700 ease-in-out"
            autoplay
            loop
            muted
        >
          <source src="@/assets/images/registration.mp4" type="video/mp4"/>
        </video>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "RegisterComponent",
  methods: {
    beginLabyAuth() {
      window.open("https://www.labymod.net/oauth2/authorize?response_type=code&client_id=globalchat&scope=basic&redirect_uri=https://globalchataddon.de/dashboard/labymod/oauth", "_self");
    },
  },
};
</script>
