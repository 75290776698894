<template>
  <th v-if="this.mute.timeout === -1"
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
  >
    Permanent
  </th>
  <th v-else
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left"
  >
    {{ this.moment(this.mute.timeout).format("DD.MM.YYYY HH:mm") }}
  </th>
  <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    <router-link :to="`/chatlog?id=${this.mute.chatLogId}`">
      <button
          class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
      >
        Chatlog
      </button>
    </router-link>
  </td>
  <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{ this.mute.reason }}
  </td>
  <td
      class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
  >
    {{ this.mute.unmuteReason }}
  </td>
</template>

<script>
import moment from "moment";

export default {
  name: "MuteComponent",
  data: () => ({
    moment: moment
  }),
  props: {
    mute: {
      type: Object,
      required: true,
    },
  },
};
</script>