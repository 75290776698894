import {ref} from 'vue'

export function useAuthorization(initalValue) {
    const authorized = ref(initalValue);

    const checkAuthorization = (cookie) => {
        if (cookie === undefined || cookie == null) {
            changeAuthorization(false);
            return;
        }

        fetch("https://api.globalchataddon.de/dashboard/token/validate", {
            method: "GET",
            headers: {
                token: cookie,
            },
        })
            .then((response) => {
                changeAuthorization(response.status === 200);
            });
    };


    const changeAuthorization = (value) => {
        authorized.value = value
    };

    return {
        authorized,
        checkAuthorization,
        changeAuthorization
    };
}