<template>
  <h1 v-if="this.user.uuid === undefined || this.user.uuid === null" class="text-center text-lg font-bold lg:text-2xl">
    Sorry, an error has occurred.<br/>
    This user does not exist or does not use GlobalChat

    <div class="relative w-full max-w-full flex-grow flex-1 text-center items-center justify-center">


      <form @submit="search(name)" class="pt-2 relative mx-auto text-white">
        <input class="bg-brand-button h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none placeholder-white"
               type="search" name="search" placeholder="Search" v-model="name">
        <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
          <svg class="text-white h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
               viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
               width="512px" height="512px">
            <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
        </button>
      </form>

    </div>
  </h1>
  <div v-else>
    <div class="rounded-t mb-0 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h1 class="mb-6 text-5xl font-bold text-white leading-snug">
            Information
          </h1>
        </div>
        <div class="relative w-full max-w-full flex-grow flex-1 text-right">


          <form @submit="search(name)" class="pt-2 relative mx-auto text-white">
            <input class="bg-brand-button h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none placeholder-white"
                   type="search" name="search" placeholder="Search" v-model="name">
            <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
              <svg class="text-white h-4 w-4 fill-current" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px"
                   viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve"
                   width="512px" height="512px">
            <path
                d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
          </svg>
            </button>
          </form>

        </div>
      </div>
    </div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full xl:w-8/12 mb-12 xl:mb-0 lg:pr-4">
        <main class="w-full flex-grow">
          <main class="h-full overflow-y-auto">
            <div class="container mx-auto grid">
              <div class="grid gap-4 mb-4 md:grid-cols-1 xl:grid-cols-3">
                <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                  <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                    <AnnotationIcon class="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div>
                    <p class="mb-2 text-base font-medium text-white">Message Count</p>
                    <p class="text-xl font-semibold text-white">{{ this.user.messageCount }}</p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                  <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                    <ClockIcon class="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div>
                    <p class="mb-2 text-base font-medium text-white">Registration Time</p>
                    <p class="text-xl font-semibold text-white">{{ this.moment(this.user.registrationTime).format("DD.MM.YYYY") }}</p>
                  </div>
                </div>
                <div class="flex items-center p-4 bg-brand-white10 rounded-lg shadow-lg">
                  <div class="p-3 mr-4 text-white bg-brand-button rounded-lg">
                    <TagIcon class="h-8 w-8" aria-hidden="true" />
                  </div>
                  <div>
                    <p class="mb-2 text-base font-medium text-white">Rank</p>
                    <p class="text-xl font-semibold text-white">{{ this.user.rank }}</p>
                  </div>
                </div>

              </div>
            </div>
          </main>
        </main>
        <div
            class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl"
        >
          <div class="rounded-t mb-0 px-3 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                  Selected prefix
                </h3>
              </div>
              <div>
                <div class="relative inline-flex">
                  <select
                      v-if="shopItems.length"
                      v-model="itemId"
                      class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                  >
                    <option :value="-1" class="placeholder-red-500 text-gray-100" disabled selected>None</option>
                    <option disabled class="placeholder-red-500 text-gray-100"
                            v-for="item in this.shopItems"
                            v-bind:value="item.id"
                            :key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <a
                      v-else
                      class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                  >
                    No prefix
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto"></div>
        </div>
        <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-4 shadow-lg rounded-xl">
          <div class="p-4">
            <h3 class="leading-6 font-semibold text-base text-white">
              External Services
            </h3>
            <div v-if="this.user.discordUserInfo !== undefined" class="mt-5">
              <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-between" style="background-image: url('https://dl.globalchataddon.de/images/discord_background.png')">
                <div class="flex items-start">
                  <img class="h-12 w-12 rounded-lg" :src="`https://cdn.discordapp.com/avatars/${this.user.discordUserInfo.id}/${this.user.discordUserInfo.avatar}.png`" alt="" /> <!---->
                  <div class="mt-0 ml-4">
                    <div class="text-white font-bold">
                      Discord
                    </div>
                    <div class="text-white">
                      {{ this.user.discordUserInfo.username + "#" + this.user.discordUserInfo.discriminator }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mt-5">
              <div class="rounded-md px-4 py-4 bg-auto bg-opacity-5 flex items-start justify-center" style="background-image: url('https://dl.globalchataddon.de/images/discord_background.png')">

                <div
                    class="items-center px-6 py-2 font-semibold text-white transition duration-500 ease-in-out transform bg-brand-button rounded-lg hover:bg-blueGray-900 focus:ring focus:outline-none"
                >
                  Not linked with Discord
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-6 shadow-lg rounded-xl"
        >
          <div class="rounded-t mb-0 px-3 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                  Ban History
                </h3>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
              <tr>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Expiration date
                </th>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Chatlog
                </th>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Reason
                </th>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Status
                </th>
              </tr>
              </thead>

              <tbody v-if="currentMute || muteHistory.length">
              <tr v-if="currentMute">
                <CurrentMuteComponent :currentMute="currentMute"/>
              </tr>
              <tr v-else></tr>
              <tr v-for="mute in this.muteHistory" :key="mute.chatLogId">
                <MuteComponent :mute="mute"/>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td
                    class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                >
                  No punishment received yet
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--
        <div class="relative flex flex-col min-w-0 break-words bg-brand-white10 text-white w-full mb-6 shadow-lg rounded-xl">
          <div class="rounded-t mb-0 px-3 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                  Connection History
                </h3>
              </div>
            </div>
          </div>
          <div class="block w-full overflow-x-auto">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
              <tr>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Time
                </th>
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Status
                </th>

              </tr>
              </thead>

              <tbody>
              <tr v-for="connection in this.connections" :key="connection.timeStamp">
                <th
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  {{ this.moment(connection.timeStamp).format("DD.MM.YYYY HH:mm") }}
                </th>
                <th v-if="connection.action === 0"
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Login
                </th>
                <th v-else
                    class="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-gray-900 py-3 text-xs uppercase border-t-0 border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  Logout
                </th>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        -->

      </div>
      <div class="w-full xl:w-4/12">
        <div
            class="relative flex flex-col min-w-0 break-words bg-brand-white10 w-full mb-6 shadow-lg rounded-xl"
        >
          <div class="rounded-lg w-full flex flex-row flex-wrap p-3 antialiased" style="
  background-image: url('https://dl.globalchataddon.de/images/background.png');
">
            <div class="w-auto">
              <img v-if="this.user.uuid !== undefined" :src="`https://render.hdskins.de/users/skin/${this.user.uuid}/face`" class="h-24 w-24"/>
            </div>
            <div class="w-3/4 flex flex-row flex-wrap">
              <div class="w-full text-right text-gray-700 font-semibold relative pt-3">
                <div class="text-2xl text-white leading-tight">{{ this.user.name }}</div>
                <div class="text-sm text-gray-300"><b>UUID:</b> <span class="border-b border-dashed hover:text-gray-400 border-gray-500 pb-1">{{ this.user.uuid }}</span></div>
                <div v-if="this.user.online" class="text-sm text-green-400 md:absolute pt-3 md:pt-0 bottom-0 right-0"><b>Online</b></div>
                <div v-else class="text-sm text-gray-300 md:absolute pt-3 md:pt-0 bottom-0 right-0"><b>Last Seen:</b> {{ this.moment(this.user.lastOnline).format("DD.MM.YYYY HH:ss") }}</div>
              </div>
            </div>
          </div>

          <div class="rounded-t mb-0 px-4 py-3 border-0">
            <div class="flex flex-wrap items-center">
              <div class="relative w-full max-w-full flex-grow flex-1">
                <h3 class="font-semibold text-base text-blueGray-700">
                <span class="flex">
                  <span>GCoins » </span>
                  <img class="px-1" src="@/assets/images/coin.svg"/>
                  <span class="px-1 text-yellow-300">{{ this.user.coins }} GCoins</span>
                </span>
                </h3>
                <h3 class="font-semibold text-base text-blueGray-700">
                  Login streak »
                  <span class="text-brand-button">{{ this.user.streak }} Days</span>
                </h3>
                <h3 class="font-semibold text-base text-blueGray-700">
                  Addon-Version »
                  <span class="text-brand-button">{{ this.user.addonVersion }}</span>
                </h3>
                <h3 class="font-semibold text-base text-blueGray-700">
                  Minecraft-Version »
                  <span class="text-brand-button">{{ this.user.minecraftVersion }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MuteComponent from "@/components/MuteComponent.vue";
import CurrentMuteComponent from "@/components/CurrentMuteComponent.vue";
import {
  AnnotationIcon,
  ClockIcon,
  TagIcon
} from '@heroicons/vue/outline'
import moment from "moment";

export default {
  name: "DashboardComponent",
  components: { MuteComponent, CurrentMuteComponent, AnnotationIcon, ClockIcon, TagIcon },
  data: () => ({
    itemId: -1,
    user: {},
    currentMute: {},
    muteHistory: [],
    connections: [],
    shopItems: [],
    streaks: {},
    moment: moment
  }),
  methods: {
    search(name) {
      this.$router.push("/dashboard/userinfo/" + name);
    },
    async loadUserInfos() {
      console.log("loading user infos")
      try {
        const response = await fetch("https://api.globalchataddon.de/userinfo", {
          method: "POST",
          headers: {
            token: this.$cookies.get("GlobalChat-Login"),
            name: this.$route.params.name
          },
        });
        const json = await response.json();

        console.log(json);

        this.user.prefix = json.prefix;
        this.user.uuid = json.uuid;
        this.user.rank = json.rank;
        this.user.name = json.name;
        this.user.coins = json.coins;
        this.user.streak = json.streak;
        this.user.messageCount = json.messageCount;
        this.user.registrationTime = json.registrationTime;
        this.user.lastOnline = json.lastOnline;
        this.user.online = json.online;
        this.user.addonVersion = json.addonVersion;
        this.user.minecraftVersion = json.minecraftVersion;

        if (json.discordUserInfo !== undefined) {
          this.user.discordUserInfo = json.discordUserInfo;
        }

        if (json.currentMute !== null) {
          this.currentMute = json.currentMute;
        }

        this.muteHistory = json.muteHistory.reverse();
        this.connections = json.connections;

        this.shopItems = json.shopItems;
        const currentShopItem = this.shopItems.find((it) => it.prefix == this.user.prefix)
        this.itemId = currentShopItem != undefined ? currentShopItem.id : -1
      } catch (error) {
        console.error("Failed to request user infos", error);
      }
    },
    async loadStreakInfos() {
      try {
        const response = await fetch(
            "https://api.globalchataddon.de/dailyRewards"
        );
        const json = await response.json();

        this.streaks = json;
      } catch (error) {
        console.error("Failed to request streak infos", error);
      }
    },
  },
  async mounted() {
    await this.loadUserInfos();
    await this.loadStreakInfos();
  },
};
</script>